<template>
  <el-row class="table">
    <span class="title">教室管理</span>
    <el-row class="row-bg">
      <el-select v-model="campusId" placeholder="请选择校区" clearable class="tb-smselect">
        <el-option v-for="item in campusArray" :key="item.id" :label="item.name" :value="item.id"></el-option>
      </el-select>
      <el-select v-model="status" placeholder="请选择教室状态" clearable class="tb-smselect">
        <el-option
          v-for="item in statusOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-button type="primary" size="medium" @click="search" class="tb-button">搜 索</el-button>
      <el-button type="primary" size="medium" @click="reset" class="tb-button">重 置</el-button>
      <el-button type="info" size="medium" @click="add" class="tb-button">新增教室</el-button>
      <!-- <el-button type="info" size="medium" @click="asyncRoom" class="tb-button">同步教室</el-button> -->
      <el-popover
        placement="top"
        width="300"
        v-model="nameVisible">
        <el-date-picker
          type="date"
          style="margin-bottom: 10px;"
          value-format="yyyy-MM-dd"
          placeholder="选择账号有效期"
          v-model="asyncTime">
        </el-date-picker>
        <div style="text-align: right; margin: 0">
          <el-button size="mini" type="info" @click="nameVisible = false">取消</el-button>
          <el-button type="primary" size="mini" @click="asyncRoom">确定</el-button>
        </div>
        <el-button slot="reference" size="medium" type="success">同步教室</el-button>
      </el-popover>
    </el-row>

    <el-table
      :data="dataList"
      border
      fit
      highlight-current-row
      class="tb-list"
    >
      <el-table-column label="教室名称" align="center" prop="title"></el-table-column>
      <el-table-column label="所属校区" align="center" prop="campusTitle"></el-table-column>
      <el-table-column label="容纳人数" align="center" prop="personCount" width="180"></el-table-column>
      <el-table-column label="状态" align="center" width="180">
        <template slot-scope="scope">
          <span :style="'color'+':'+getStateColor(scope.row.status)">{{scope.row.status}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="300">
        <template slot-scope="scope">
          <el-button
            size="small"
            type="success"
            plain
            v-if="scope.row.status === '已禁用'"
            @click="start(scope.row)"
          >启 用</el-button>
          <el-button
            v-if="scope.row.status === '已启用'"
            size="small"
            type="danger"
            plain
            @click="stop(scope.row)"
          >停 用</el-button>
          <el-button
            size="small"
            type="primary"
            plain
            @click="edit(scope.row)"
          >编 辑</el-button>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pagination.currentPage"
      :page-sizes="pagination.pageSizes"
      :page-size="pagination.pageSize"
      :layout="pagination.layout"
      :total="pagination.total"
    ></el-pagination>

    <!-- addRoom -->
    <add-room :show="roomDialog" @close="roomDialog=false;editRoom=null" :editRoom="editRoom" @updateView="getClassroomList"></add-room>
  </el-row>
</template>

<script>
import { getClassroomList, editClassroom, replaceByXgj } from '@/api/senate/room'
import { getMyCampus } from '@/api/senate/class'
import getStateColor from '@/mixins/getStateColor'
import addRoom from '@/components/senate/add-room'

export default {
  components: {
    addRoom
  },
  data () {
    return {
      status: '',
      campusId: '',
      dataList: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 30, 50, 100],
        layout: 'total, sizes, prev, pager, next, jumper',
        total: 0
      },
      roomDialog: false,
      editRoom: null,
      statusOptions: [
        {
          value: '已启用',
          label: '已启用'
        },
        {
          value: '已禁用',
          label: '已禁用'
        }
      ],
      asyncTime: null,
      campusArray: [],
      nameVisible: false,
      // pickerOptions: {
      //   disabledDate(time) {
      //     return time.getTime() < Date.now();
      //   },
      // },
    }
  },
  mixins: [getStateColor],
  created () {
    this.getMyCampus()
    this.getClassroomList()
  },
  methods: {
    async getMyCampus () {
      const res = await getMyCampus({})
      this.campusArray = res.body
    },

    async getClassroomList () {
      const res = await getClassroomList({
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        onlyMyCampus: true,
        status: this.status,
        campusId: this.campusId
      })
      this.dataList = res.body.list
      this.pagination.total = res.body.total
    },

    search () {
      this.pagination.currentPage = 1
      this.getClassroomList()
    },

    reset () {
      this.status = ''
      this.campusId = ''
    },

    add () {
      this.roomDialog = true
    },

    edit (row) {
      this.editRoom = row
      this.roomDialog = true
    },

    asyncRoom () { // 同步教室
      replaceByXgj({
        updateTime: this.asyncTime
      }).then(res => {
        if (res.state === 'success') {
          this.nameVisible = false
          window.$msg('同步成功')
          this.getClassroomList()
        }
      })
    },

    start (row) {
      editClassroom({
        id: row.id,
        status: '已启用'
      }).then(res => {
        if (res.state === 'success') {
          window.$msg('启用成功')
          this.getClassroomList()
        }
      })
    },

    stop (row) {
      this.$confirm('是否禁用当前教室', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          await editClassroom({
            id: row.id,
            status: '已禁用'
          })
          window.$msg('禁用成功')
          this.getClassroomList()
        })
    },

    // 分页
    handleCurrentChange (val) {
      this.pagination.currentPage = val
      this.getClassroomList()
    },
    handleSizeChange (val) {
      this.pagination.pageSize = val
      this.getClassroomList()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../style/table.scss";
</style>
