var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.show,
        "before-close": _vm.close,
        "append-to-body": "",
        "close-on-click-modal": false,
        top: "7vh",
      },
      on: {
        "update:visible": function ($event) {
          _vm.show = $event
        },
        open: _vm.openDialog,
      },
    },
    [
      _c(
        "div",
        { staticClass: "dia-tit", attrs: { slot: "title" }, slot: "title" },
        [
          _c("i"),
          _c("span", { domProps: { textContent: _vm._s(_vm.roomText) } }),
        ]
      ),
      _c(
        "el-row",
        [
          _c(
            "el-form",
            {
              ref: "formData",
              attrs: {
                rules: _vm.rules,
                model: _vm.formData,
                "label-width": "120px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "教室名称", prop: "title" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入教室名称" },
                    model: {
                      value: _vm.formData.title,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "title", $$v)
                      },
                      expression: "formData.title",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "所属校区", prop: "campusId" } },
                [
                  _c(
                    "el-select",
                    {
                      staticClass: "tb-smselect",
                      attrs: { placeholder: "请选择校区", clearable: "" },
                      model: {
                        value: _vm.formData.campusId,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "campusId", $$v)
                        },
                        expression: "formData.campusId",
                      },
                    },
                    _vm._l(_vm.campusArray, function (item) {
                      return _c("el-option", {
                        key: item.id,
                        attrs: { label: item.name, value: item.id },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "容纳人数" } },
                [
                  _c("el-input-number", {
                    attrs: { min: 1, label: "教室容纳人数" },
                    model: {
                      value: _vm.formData.personCount,
                      callback: function ($$v) {
                        _vm.$set(_vm.formData, "personCount", $$v)
                      },
                      expression: "formData.personCount",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "教室状态" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.formData.status,
                        callback: function ($$v) {
                          _vm.$set(_vm.formData, "status", $$v)
                        },
                        expression: "formData.status",
                      },
                    },
                    [
                      _c("el-radio", { attrs: { label: "已启用" } }),
                      _c("el-radio", { attrs: { label: "已禁用" } }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        {
          staticClass: "text-right",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              attrs: { type: "info", size: "medium" },
              on: { click: _vm.close },
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary", size: "medium" },
              on: { click: _vm.confirm },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }