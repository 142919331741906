<template>
  <el-dialog
    :visible.sync="show"
    :before-close="close"
    @open="openDialog"
    append-to-body
    :close-on-click-modal="false"
    top="7vh"
  >
    <div slot="title" class="dia-tit">
      <i></i>
      <span v-text="roomText"></span>
    </div>

    <el-row>
      <el-form ref="formData" :rules="rules" :model="formData" label-width="120px">
        <el-form-item label="教室名称" prop="title">
          <el-input v-model="formData.title" placeholder="请输入教室名称"></el-input>
        </el-form-item>
        <el-form-item label="所属校区" prop="campusId">
          <el-select v-model="formData.campusId" placeholder="请选择校区" clearable class="tb-smselect">
            <el-option v-for="item in campusArray" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="容纳人数">
          <el-input-number v-model="formData.personCount" :min="1" label="教室容纳人数"></el-input-number>
        </el-form-item>
        <el-form-item label="教室状态">
          <el-radio-group v-model="formData.status">
            <el-radio label="已启用"></el-radio>
            <el-radio label="已禁用"></el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </el-row>

    <el-row slot="footer" class="text-right">
      <el-button @click="close" type="info" size="medium">取 消</el-button>
      <el-button type="primary" @click="confirm" size="medium">确 定</el-button>
    </el-row>

  </el-dialog>
</template>

<script>
import { addClassroom, editClassroom } from '@/api/senate/room'
import { getMyCampus } from '@/api/senate/class'

export default {
  props: {
    show: {
      default: false,
      type: Boolean
    },
    editRoom: {
      default: () => {
        return {}
      },
      type: Object
    }
  },
  watch: {
    show () {
      if (this.show) {
        if (this.$refs.formData) {
          this.$refs.formData.resetFields()
        }
      }
    }
  },
  data () {
    return {
      formData: {},
      roomText: '',
      campusArray: [], // 校区列表
      rules: {
        title: [{ required: true, message: '请输入教室名称，同一校区下教室名称不能重复', trigger: 'blur' }],
        campusId: [{ required: true, message: '请选择校区', trigger: 'blur' }],
      }
    }
  },
  methods: {
    openDialog () {
      this.formData = {
        title: '',
        campusId: '',
        personCount: 0,
        status: '已启用'
      }
      if (this.editRoom === null) {
        this.roomText = '新增教室'
      } else {
        this.roomText = '编辑教室'
        this.formData = Object.assign({}, this.editRoom)
      }
      this.getMyCampus()
    },

    async getMyCampus () {
      const res = await getMyCampus({})
      this.campusArray = res.body
    },

    close () { this.$emit('close') },

    confirm () {
      this.$refs.formData.validate(valid => {
        if (valid) {
          if (this.editRoom === null) {
            addClassroom({...this.formData}).then(res => {
              if (res.state === 'success') {
                window.$msg('新增成功')
                this.close()
                this.$emit('updateView')
              }
            })
          } else {
            editClassroom({...this.formData}).then(res => {
              if (res.state === 'success') {
                window.$msg('编辑成功')
                this.close()
                this.$emit('updateView')
              }
            })
          }
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/style/dialog.scss";
</style>
