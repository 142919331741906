var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-row",
    { staticClass: "table" },
    [
      _c("span", { staticClass: "title" }, [_vm._v("教室管理")]),
      _c(
        "el-row",
        { staticClass: "row-bg" },
        [
          _c(
            "el-select",
            {
              staticClass: "tb-smselect",
              attrs: { placeholder: "请选择校区", clearable: "" },
              model: {
                value: _vm.campusId,
                callback: function ($$v) {
                  _vm.campusId = $$v
                },
                expression: "campusId",
              },
            },
            _vm._l(_vm.campusArray, function (item) {
              return _c("el-option", {
                key: item.id,
                attrs: { label: item.name, value: item.id },
              })
            }),
            1
          ),
          _c(
            "el-select",
            {
              staticClass: "tb-smselect",
              attrs: { placeholder: "请选择教室状态", clearable: "" },
              model: {
                value: _vm.status,
                callback: function ($$v) {
                  _vm.status = $$v
                },
                expression: "status",
              },
            },
            _vm._l(_vm.statusOptions, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
          _c(
            "el-button",
            {
              staticClass: "tb-button",
              attrs: { type: "primary", size: "medium" },
              on: { click: _vm.search },
            },
            [_vm._v("搜 索")]
          ),
          _c(
            "el-button",
            {
              staticClass: "tb-button",
              attrs: { type: "primary", size: "medium" },
              on: { click: _vm.reset },
            },
            [_vm._v("重 置")]
          ),
          _c(
            "el-button",
            {
              staticClass: "tb-button",
              attrs: { type: "info", size: "medium" },
              on: { click: _vm.add },
            },
            [_vm._v("新增教室")]
          ),
          _c(
            "el-popover",
            {
              attrs: { placement: "top", width: "300" },
              model: {
                value: _vm.nameVisible,
                callback: function ($$v) {
                  _vm.nameVisible = $$v
                },
                expression: "nameVisible",
              },
            },
            [
              _c("el-date-picker", {
                staticStyle: { "margin-bottom": "10px" },
                attrs: {
                  type: "date",
                  "value-format": "yyyy-MM-dd",
                  placeholder: "选择账号有效期",
                },
                model: {
                  value: _vm.asyncTime,
                  callback: function ($$v) {
                    _vm.asyncTime = $$v
                  },
                  expression: "asyncTime",
                },
              }),
              _c(
                "div",
                { staticStyle: { "text-align": "right", margin: "0" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { size: "mini", type: "info" },
                      on: {
                        click: function ($event) {
                          _vm.nameVisible = false
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", size: "mini" },
                      on: { click: _vm.asyncRoom },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
              _c(
                "el-button",
                {
                  attrs: { slot: "reference", size: "medium", type: "success" },
                  slot: "reference",
                },
                [_vm._v("同步教室")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticClass: "tb-list",
          attrs: {
            data: _vm.dataList,
            border: "",
            fit: "",
            "highlight-current-row": "",
          },
        },
        [
          _c("el-table-column", {
            attrs: { label: "教室名称", align: "center", prop: "title" },
          }),
          _c("el-table-column", {
            attrs: { label: "所属校区", align: "center", prop: "campusTitle" },
          }),
          _c("el-table-column", {
            attrs: {
              label: "容纳人数",
              align: "center",
              prop: "personCount",
              width: "180",
            },
          }),
          _c("el-table-column", {
            attrs: { label: "状态", align: "center", width: "180" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "span",
                      {
                        style:
                          "color" + ":" + _vm.getStateColor(scope.row.status),
                      },
                      [_vm._v(_vm._s(scope.row.status))]
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { label: "操作", align: "center", width: "300" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.status === "已禁用"
                      ? _c(
                          "el-button",
                          {
                            attrs: {
                              size: "small",
                              type: "success",
                              plain: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.start(scope.row)
                              },
                            },
                          },
                          [_vm._v("启 用")]
                        )
                      : _vm._e(),
                    scope.row.status === "已启用"
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "small", type: "danger", plain: "" },
                            on: {
                              click: function ($event) {
                                return _vm.stop(scope.row)
                              },
                            },
                          },
                          [_vm._v("停 用")]
                        )
                      : _vm._e(),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "small", type: "primary", plain: "" },
                        on: {
                          click: function ($event) {
                            return _vm.edit(scope.row)
                          },
                        },
                      },
                      [_vm._v("编 辑")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("el-pagination", {
        attrs: {
          "current-page": _vm.pagination.currentPage,
          "page-sizes": _vm.pagination.pageSizes,
          "page-size": _vm.pagination.pageSize,
          layout: _vm.pagination.layout,
          total: _vm.pagination.total,
        },
        on: {
          "size-change": _vm.handleSizeChange,
          "current-change": _vm.handleCurrentChange,
        },
      }),
      _c("add-room", {
        attrs: { show: _vm.roomDialog, editRoom: _vm.editRoom },
        on: {
          close: function ($event) {
            _vm.roomDialog = false
            _vm.editRoom = null
          },
          updateView: _vm.getClassroomList,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }